import Menu from "./Menu";
import { Link } from "react-router-dom";
import Logo from "../../images/csv-xxl.png";
import { AccountContext } from "../../cognito/Account.jsx";
import React, { useEffect, useState } from "react";

const Header = () => {
    const [ userFirstName, setUserFirstName ] = useState(""); // The user's first name

    const { getSession } = React.useContext(AccountContext);

    useEffect(() => {
        // WARNING: The call below is technically a misuse of getSession() and should not generally be replicated since the ID token payload is only updated every 60 minutes.
        // However, this is an instantaneous way to get the user's first name without making an API call that requires a loading period.
        // The side effect of this of course is that the user's first name will not update in the header until the ID token is updated, but for this circumstance it is worth it.
        getSession()
        .then(session => {
            // Get the user's first name by finding the attribute and splicing it from the rest of the name:
            let name = session.idToken.payload.name;
            name = name.split(" ")[0];
            setUserFirstName(name);
        })
        .catch(err => {
            // Do nothing, the user simply isn't logged in.
        });
    }, []);

    const menuItems = [
        {
            name: "Contact",
            to: "/contact", 
            icon: () => (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                </svg>
            )
        },
        /**
         * UNCOMMENT BELOW TO SHOW PROFILE MENU ITEM
         * ENABLES COGNITO REGISTRATION AND LOGIN
         */

        {
            name: userFirstName ? userFirstName : "Login",
            to: "/profile",
            icon: () => (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                </svg>
            )
        }
    ];

    return (
        <header className="bg-primaryBackground px-5 py-3 md:px-12 sm:py-8 text-md sm:text-xl flex items-center justify-between shadow text-white">
            <div className="flex items-center">
                <div className="inline-block">
                    <Link to="/">
                        {/*<div tabIndex="1" onKeyDown={clickOnEnter} className="hover:cursor-pointer">*/}
                        <img src={Logo} className="w-8 h-8 mr-3 inline-block" alt="Export Holdings Logo"/>
                        <div className="inline-block">Export Holdings</div>
                        {/*
                        <svg className="w-12 h-6 inline-block px-2 -mt-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                        </svg>
                        */}
                    </Link>
                </div>
            </div>
            <div className="inline-block">
                <Menu items={menuItems} />
            </div>
        </header>
    );
};

export default Header;