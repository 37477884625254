import React, { useState } from 'react';

const Tooltip = ({ children, content }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className="relative flex items-center justify-center">
            <div
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className="cursor-pointer"
            >
                {children}
            </div>
            {isHovered && (
                <div className="absolute bottom-full mb-2 px-4 py-2 bg-black text-white text-sm rounded-md shadow-md whitespace-nowrap">
                    {content}
                </div>
            )}
        </div>
    );
};

export default Tooltip;