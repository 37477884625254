import Header from "../parts/Header";
import Footer from "../parts/Footer";
import Title from "../parts/Title";
import Section from "../parts/Section";
import Button from "../parts/Button";
import Notification from "../parts/Notification";
import { AccountContext } from "../../cognito/Account";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import TinyLoader from "../parts/TinyLoader";
import Billing from "../form-parts/Billing";

const Profile = () => {
    const [loggedIn, setLoggedIn] = useState(true);
    const [logoutSuccess, setLogoutSuccess] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [loading, setLoading] = useState(true);
    const [updatedProfileSuccess, setUpdatedProfileSuccess] = useState(false);

    const { getUserAttributes, logout } = React.useContext(AccountContext);

    useEffect(() => {
        getUserAttributes()
        .then(attributes => {
            // Get information about the user:
            let userInfo = {};

            attributes.forEach(attribute => {
                userInfo[attribute.Name] = attribute.Value;
            });

            setUserInfo(userInfo);

            setLoading(false);

            // Check for an updated profile success URL parameter in order to display a success notification:
            const params = new URLSearchParams(window.location.search);
            const updatedProfileSuccess = params.get("updatedProfileSuccess");

            if (updatedProfileSuccess == "true") {
                setUpdatedProfileSuccess(true);
            }
        })
        .catch(err => {
            // Redirect to the login page:
            setLoggedIn(false);
            setLoading(false);
        });
    }, []);

    const handleLogout = () => {
        logout();
        setLogoutSuccess(true); // This is used to display a success message on the login page.
        setLoggedIn(false);
    }

    return (
        <div>
            <Header />
            {loading ? (
                <Section fullHeight={true}>
                    <Title>Loading...</Title>
                    <TinyLoader />
                </Section>
            ) :
                loggedIn ? (
                <>
                    <Section>
                        <Title>Profile</Title>
                        <div className="flex flex-wrap gap-x-5 mb-6">
                            <div>
                                <p>
                                    Welcome to your dashboard, {userInfo.name}!
                                </p>
                                <p className="text-gray-600 mt-3">
                                    {userInfo.email}
                                </p>
                            </div>
                            <div className="mt-3 md:mt-0 ml-auto basis-full md:basis-3/5 flex gap-x-2 md:justify-end">
                                <div className="w-full">
                                    <Link to="/settings">
                                        <Button>
                                            Settings
                                        </Button>
                                    </Link>
                                </div>
                                <Button onClick={handleLogout}>Log out</Button>
                            </div>
                        </div>
                    </Section>
                <Billing />
                </>
                ) : <Navigate to={"/login?logoutSuccess=" + logoutSuccess} replace />
            }
            <Footer />
            <Notification show={updatedProfileSuccess} toggleShowCallback={() => setUpdatedProfileSuccess(false)}>
                Your account was successfully updated.
            </Notification>
        </div>
    );
}

export default Profile;