import Section from "../parts/Section";
import Header from "../parts/Header";
import Footer from "../parts/Footer";
import FormContainer from "../form-parts/FormContainer";
import TextInput from "../form-parts/TextInput";
import PasswordInput from "../form-parts/PasswordInput";
import { Link, Navigate } from "react-router-dom";
import React from "react";
import UserPool from "../../cognito/UserPool";

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            password: "",
            error: "",
            loading: false,
            showPassword: false,
            redirectPath: ""
        };
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, error: null });
    }

    toggleShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true });

        const nameAttribute = {
            Name: "name",
            Value: this.state.name,
        }

        UserPool.signUp(this.state.email, this.state.password, [nameAttribute], null, (err, data) => {
            if (err) {
                this.setState({ loading: false, error: err.message });
            } else {
                this.setState({ loading: false, success: true });
            }
        });
    }

    componentDidMount() {
        // Get a prefilled email and redirect link from the URL:
        const urlParams = new URLSearchParams(window.location.search);
        const prefilledEmail = urlParams.get("prefilledEmail");
        const redirectPath = urlParams.get("redirectPath");

        if (prefilledEmail) {
            this.setState({ email: prefilledEmail });
        }

        if (redirectPath) {
            this.setState({ redirectPath: redirectPath });
        }
    }

    render = () => (
        <div>
            <Header />
            <Section fullHeight={true}>
                <FormContainer
                    title="Register for an Account"
                    submitLabel="Register"
                    onSubmit={this.handleSubmit}
                    loading={this.state.loading}
                    success={this.state.success}
                    error={this.state.error}
                    otherOption={() => <>Already have an account? <Link to={"/login?redirectPath=" + this.state.redirectPath} className="underline">Log in</Link></>}
                    onSuccess={() => <Navigate to={"/verify?email=" + this.state.email + "&redirectPath=" + this.state.redirectPath} />}
                >
                    <TextInput
                        label="Name"
                        name="name"
                        type="text"
                        placeholder="Name"
                        value={this.state.name}
                        onChange={this.handleChange}
                    />
                    <TextInput
                        label="Email"
                        name="email"
                        type="email"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={this.handleChange}
                    />
                    <PasswordInput
                        label="Password"
                        name="password"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={this.handleChange}
                        note={() => <>Passwords must be 9 or more characters long with upper and lowercase letters.</>}
                    />
                </FormContainer>
            </Section>
            <Footer />
        </div>
    );
}

export default Register;