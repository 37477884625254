import { useState, useEffect } from "react";
import axios from 'axios';
import Section from "../parts/Section";
import Header from "../parts/Header";
import Footer from "../parts/Footer";
import FormContainer from "../form-parts/FormContainer";
import TextInput from "../form-parts/TextInput";
import TextboxInput from "../form-parts/TextboxInput";
import Notification from "../parts/Notification";

const Contact = () => {
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    //const [selectedSubject, setSelectedSubject] = useState('');
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        subject: 'report-bug',
        message: ''
    });

    // Scroll to top on page load
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Set the subject value in the form data to the URL parameter by default:
    useEffect(() => {
        if (window.location.search) {
            const urlParams = new URLSearchParams(window.location.search);
            const defaultSubject = urlParams.get('defaultSubject');
            setFormData({
                ...formData,
                subject: defaultSubject
            });
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);

        const payload = {
            "operation": "putUserMessage",
            "payload": formData
        }
        payload.payload.subject = formData.subject;

        try {
            const response = await axios.post(process.env.REACT_APP_API_URL, payload);
            console.log('POST request successful:', response.data);
            // Handle success, e.g., show a success message
            // Clear the form fields after successful submit
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                subject: '',
                message: ''
            });

            setLoading(false);
            setSuccess(true);

        } catch (error) {
            // Handle error, e.g., show an error message
            setLoading(false);
            setError(error);
            console.error('Error submitting form:', error);
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div data-testid="contact-component">
            <Header />
            <Section fullHeight={true}>
                <FormContainer
                    title="Contact Us"
                    submitLabel="Send"
                    onSubmit={handleSubmit}
                    loading={loading}
                    success={success}
                    error={error}
                    showFormOnSuccess={true}
                >
                    <div className="sm:col-span-full">
                        <p className="text-base leading-7 text-gray-900">Note regarding Redistribution:</p>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            Exported files are intended for internal business use only, not for public display or redistribution. If you're looking for a redistribution license, please contact us.
                        </p>
                    </div>

                    <TextInput
                        label="First Name"
                        name="firstName"
                        type="text"
                        value={formData.firstName}
                        onChange={handleChange}
                        required={false}
                    />
                    <TextInput
                        label="Last Name"
                        name="lastName"
                        type="text"
                        value={formData.lastName}
                        onChange={handleChange}
                        required={false}
                    />
                    <TextInput
                        label="Email"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                    />

                    <div className="sm:col-span-3">
                        <label htmlFor="subject" className="block font-medium leading-6 text-gray-900">
                            How can we help?
                        </label>
                        <div className="mt-2">
                            <select
                                value={formData.subject}
                                onChange={handleChange}
                                id="subject"
                                name="subject"
                                className="block w-full px-3 rounded-md border-0 h-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                            >
                                <option value="report-bug">Report a bug</option>
                                <option value="add-fund">Request additional fund coverage</option>
                                <option value="license">Redistribution license</option>
                                <option value="customer-support">Customer support</option>
                                <option value="other">Something else</option>
                            </select>
                        </div>
                    </div>

                    <TextboxInput
                        label="Message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        note={() => <>Thank you for writing us, we appreciate your feedback &#x1F642;</>}
                    />

                </FormContainer>
            </Section>
            <Footer />
            <Notification show={success} toggleShowCallback={() => setSuccess(false)}>
                <p className="text-sm font-medium text-gray-900">We've received your message,</p>
                <p className="mt-1 text-sm text-gray-900">thanks for reaching out!</p>
            </Notification>
        </div>
    )
};

export default Contact;