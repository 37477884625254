import React from "react";
import Title from "../parts/Title";
import Section from "../parts/Section";
import Header from "../parts/Header";
import Footer from "../parts/Footer";
import ServiceBar from "../parts/ServiceBar";

const GeneralError = () =>
    <div className="text-center" data-testid="error-component">
        <Header />
        <div className="px-4 py-5 sm:p-6">
            <Section>
                <Title>An Error Occurred</Title>
                <ul class="list-inside list-disc ml-6">
                    <li>Please try again later.</li>
                </ul>
                <ServiceBar />
            </Section>
        </div>
        <Footer />
    </div>;

export default GeneralError;