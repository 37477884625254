export default function sortRows(rows, sortByField, descending = false) {
  const fieldTypeMapping = {
    ticker: "string",
    cusip: "string",
    nameOfIssuer: "string",
    date: "string",
    changeType: "string",
    value: "number",
    sshPrnamt: "number",
    percentageOfPortfolio: "number",
    previousPercentageOfPortfolio: "number",
    changeInShares: "number",
    changePercentage: "number",
    titleOfClass: "string",
    sshPrnamtType: "string",
    sector: "string",
    close: "number",
    peRatio: "number",
    pbRatio: "number",
    priceToRevenue: "number",
    priceToFCF: "number",
    "EV/EBIT": "number",
    "EV/EBITDA": "number",
    operatingMargin: "number",
    netMargin: "number",
    assetTurnover: "number",
    leverageRatio: "number",
    returnOnEquity: "number",
    currentRatio: "number",
    quickRatio: "number",
    "debt/EBITDA": "number",
    "debt/Equity": "number"
  }
  const type = fieldTypeMapping[sortByField];
  switch (type) {
    case "string":
      descending ? rows?.sort((a, b) => b[sortByField].localeCompare(a[sortByField])) : rows?.sort((a, b) => a[sortByField].localeCompare(b[sortByField]));
      break;
    case "number":
      if (descending) {
        rows?.sort((a,b) => {
          const numA = Number(a[sortByField]);
          const numB = Number(b[sortByField]);
          if (numA < numB) {
            return -1;
          } else if (numA > numB) {
            return 1;
          } else {
            return 0;
          }
        })
      } else {
        rows?.sort((a,b) => {
          const numA = Number(a[sortByField]);
          const numB = Number(b[sortByField]);
          if (numA > numB) {
            return -1;
          } else if (numA < numB) {
            return 1;
          } else {
            return 0;
          }
        })
      }
      break;
    default:
      descending ? rows?.sort((a, b) => b[sortByField] - a[sortByField]) : rows?.sort((a, b) => a[sortByField] - b[sortByField]);
  }

  return rows;
};