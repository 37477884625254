import FormContainer from "../form-parts/FormContainer";
import TextInput from "../form-parts/TextInput";
import PasswordInput from "../form-parts/PasswordInput";
import Header from "../parts/Header";
import Footer from "../parts/Footer";
import { Navigate } from "react-router-dom";
import Notification from "../parts/Notification";
import React from "react";
import UserPool from "../../cognito/UserPool";
import { CognitoUser } from "amazon-cognito-identity-js";

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            code: "",
            password: "",
            primaryError: "",
            primaryLoading: false,
            primarySuccess: false,
            secondaryError: "",
            secondaryLoading: false,
            secondarySuccess: false,
            showCodeResent: false
        };
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, primaryError: null, secondaryError: null });
    }

    // First try to get the user's email from the URL:
    componentDidMount = () => {
        const params = new URLSearchParams(window.location.search);
        const email = params.get("email");

        if (email) {
            this.setState({ email: email });

            // Wait for the email to be set, then submit the form:
            this.handlePrimarySubmit({
                preventDefault: () => {}
            }, email);
        }
    }

    // Send a confirmation code to the user's email:
    handlePrimarySubmit = (e, defaultEmail = null) => {
        e.preventDefault();

        this.setState({ primaryLoading: true });

        // Construct a user object to send the code to:
        const user = new CognitoUser({ Username: defaultEmail ? defaultEmail : this.state.email, Pool: UserPool });

        user.forgotPassword({
            onSuccess: () => {
                this.setState({ primaryLoading: false, primarySuccess: true });
            },
            onFailure: (err) => {
                this.setState({ primaryLoading: false, primaryError: err.message });
            }
        });
    }

    // Confirm the code and reset the user's password:
    handleSecondarySubmit = (e) => {
        e.preventDefault();
        this.setState({ secondaryLoading: true });

        // Construct a user object to reset the password:
        const user = new CognitoUser({ Username: this.state.email, Pool: UserPool });

        user.confirmPassword(this.state.code, this.state.password, {
            onSuccess: () => {
                this.setState({ secondaryLoading: false, secondarySuccess: true });
            },
            onFailure: (err) => {
                this.setState({ secondaryLoading: false, secondaryError: err.message });
            }
        });
    }

    resendCode = () => {
        this.setState({ primaryLoading: true, showCodeResent: false });
        
        // Construct a user object to resend the code to:
        const user = new CognitoUser({
            Username: this.state.email,
            Pool: UserPool
        });

        // Resend the code:
        user.resendConfirmationCode((err, data) => {
            if (err) {
                this.setState({ primaryLoading: false, primaryError: err.message });
            } else {
                this.setState({ primaryLoading: false, showCodeResent: true });
            }
        });
    }

    render = () => (
        <div>
            <Header />
            {!this.state.primarySuccess ? (
            <FormContainer 
                title="Reset your Password"
                submitLabel="Send"
                onSubmit={this.handlePrimarySubmit}
                loading={this.state.primaryLoading}
                success={this.state.primarySuccess}
                error={this.state.primaryError}
            >
                <TextInput
                    label="Email"
                    name="email"
                    type="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    note={() => "Enter the email associated with your account and we'll send a verification code to it."}
                />
            </FormContainer>
            ) : (
            <FormContainer
                title="Reset your Password"
                submitLabel="Reset"
                onSubmit={this.handleSecondarySubmit}
                loading={this.state.secondaryLoading}
                success={this.state.secondarySuccess}
                error={this.state.secondaryError}
                onSuccess={() => <Navigate to="/profile?updatedProfileSuccess=true" />}
            >
                <TextInput
                    label="Code"
                    name="code"
                    value={this.state.code}
                    onChange={this.handleChange}
                    note={() => 
                        <>Enter the code sent to your email address or <button className="cursor-pointer underline" onClick={this.resendCode}>resend the code</button>.</>
                    }
                />
                <PasswordInput
                    label="New Password"
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    note={() => <>Passwords must be 9 or more characters long with upper and lowercase letters.</>}
                />
            </FormContainer>
            )}
            <Footer />
            <Notification show={this.state.showCodeResent} toggleShowCallback={() => this.setState({ showCodeResent: false })}>
                Code was resent to: <br />{this.state.email}
            </Notification>
        </div>
    );
}

export default ResetPassword;