const TextInput = ({ label, name, value, onChange, required = true, type = "text", note = () => null }) => (
    <div className="sm:col-span-full">
        <label htmlFor={type} className="block font-medium leading-6 text-gray-900">
            {label}
        </label>
        <div className="mt-2">
            <input
                id={name}
                name={name}
                type={type}
                autoComplete={name}
                required={required}
                value={value}
                onChange={onChange}
                placeholder={required ? "required" : "optional"}
                className="block w-full px-3 rounded-md border-0 border-transparent py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
        </div>
        <p className="text-sm leading-6 text-gray-600 mt-3">{note()}</p>
    </div>
);

export default TextInput;