import Section from "../parts/Section";
import Header from "../parts/Header";
import Footer from "../parts/Footer";
import FormContainer from "../form-parts/FormContainer";
import TextInput from "../form-parts/TextInput";
import PasswordInput from "../form-parts/PasswordInput";
import { Link, Navigate } from "react-router-dom";
import React from "react";
import { AccountContext } from "../../cognito/Account.jsx";
import Notification from "../parts/Notification.jsx";
import UserPool from "../../cognito/UserPool.js";
import { CognitoUser } from "amazon-cognito-identity-js";

class LoginClass extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            error: "",
            loading: false,
            success: false,
            userNotConfirmed: false,
            registrationSuccess: false,
            logoutSuccess: false,
            redirectPath: ""
        };
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, error: null });
    }

    componentDidMount = () => {
        // Make sure that the user isn't already logged in:
        this.props.context.getSession()
        .then(session => {
            this.setState({ success: true });
        })
        .catch(err => {});

        // Check for registration success, redirect URL, and logout success URL parameters:
        const params = new URLSearchParams(window.location.search);
        const registrationSuccess = params.get("registrationSuccess");
        const logoutSuccess = params.get("logoutSuccess");
        const redirectPath = params.get("redirectPath");

        if (registrationSuccess == "true") {
            this.setState({ registrationSuccess: true });
        }

        if (logoutSuccess == "true") {
            this.setState({ logoutSuccess: true });
        }

        if (redirectPath) {
            this.setState({ redirectPath: redirectPath });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({ loading: true });

        this.props.context.authenticate(this.state.email, this.state.password)
        .then(data => {
            this.setState({ loading: false, success: true });
        })
        .catch(err => {
            // If the user is not confirmed already, redirect to the email verification page:
            if (err.code === "UserNotConfirmedException") {
                // Construct a user object to resend a confirmation code to:
                const user = new CognitoUser({
                    Username: this.state.email,
                    Pool: UserPool
                });

                // Resend the code:
                user.resendConfirmationCode((err, data) => {
                    if (err) {
                        this.setState({ loading: false, error: err.message });
                    } else {
                        this.setState({ loading: false, showCodeResent: true });
                    }
                });

                // Now redirect to the verification page where the user can enter the code:
                this.setState({ userNotConfirmed: true });
                return;
            }

            this.setState({ loading: false, error: err.message });
        });
    }

    render = () => (
        <div>
            <Header />
            <Section fullHeight={true}>
                <FormContainer 
                    title="Login"
                    submitLabel="Log in"
                    onSubmit={this.handleSubmit}
                    loading={this.state.loading}
                    success={this.state.success}
                    error={this.state.error}
                    onSuccess={() => this.state.redirectPath === "" ? <Navigate to="/profile" replace /> : <Navigate to={this.state.redirectPath} replace />}
                    otherOption={() => <>Don't have an account? <Link to={"/register?redirectPath=" + this.state.redirectPath} className="underline">Register</Link></>}
                >
                    <TextInput
                        label="Email"
                        name="email"
                        type="email"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={this.handleChange}
                    />
                    <PasswordInput
                        label="Password"
                        name="password"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={this.handleChange}
                        note={() => <p className="text-sm leading-6 text-gray-600 -mt-1 cursor-pointer underline"><Link to="/reset-password">Forgot your password?</Link></p>}
                    />
                </FormContainer>

                { // Redirect user to confirm their account with their email if they didn't already after they registered:
                this.state.userNotConfirmed ? (
                    <Navigate to={"/verify?email=" + this.state.email + "&redirectPath=" + this.state.redirectPath} replace /> 
                ) : (
                    null
                )
                }

                <Notification show={this.state.registrationSuccess} toggleShowCallback={() => this.setState({ registrationSuccess: false })}>
                    Your account has been successfully created.
                    Now just log in to get started.
                </Notification>
                <Notification show={this.state.logoutSuccess} toggleShowCallback={() => this.setState({ logoutSuccess: false })}>
                    You have been successfully logged out.
                </Notification>
            </Section>
            <Footer />
        </div>
    );
}

export default function Login() {
    const context = React.useContext(AccountContext);

    return <LoginClass context={context} />;
}