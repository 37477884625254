import React from 'react';
import Button from '../parts/Button.jsx';
import Section from '../parts/Section.jsx';
import { CheckIcon } from '@heroicons/react/20/solid'
import { AccountContext } from '../../cognito/Account.jsx';
import ServiceBar from '../parts/ServiceBar.jsx';
import axios from 'axios';

class BillingClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      subscribed: null,
    };
  }

  // Get the link to Stripe's checkout page:
  createStripeCheckoutSession = () => {
    this.setState({ loading: true });

    this.props.context.getSession()
    .then(session => (
      axios.post(process.env.REACT_APP_API_URL, {
        "operation": "createStripeCheckoutSession"
      }, {
        headers: {
          "Authorization": session.accessToken.jwtToken
        }
      }).then(response => {
        this.setState({ checkoutURL: response.data.checkoutURL });

        window.location.href = response.data.checkoutURL;

      }).catch(error => {
        console.log(error);

        this.setState({ loading: false, error: error.message});
      })

    ));
  }

  // Get the link to Stripe's billing portal page:
  createStripeCustomerPortalSession = () => {
    this.setState({ loading: true });

    this.props.context.getSession()
    .then(session => (
      axios.post(process.env.REACT_APP_API_URL, {
        "operation": "createStripeCustomerPortalSession"
      }, {
        headers: {
          "Authorization": session.accessToken.jwtToken
        }
      }).then(response => {
        this.setState({ portalURL: response.data.portalURL });

        window.location.href = response.data.portalURL;

      }).catch(error => {
        console.log(error);

        this.setState({ loading: false, error: error.message});
      })

    ));
  }

  // Automatically check if the user is subscribed to the Pro plan:
  componentDidMount = () => {
    this.setState({ loading: true });

    this.props.context.getSession()
    .then(session => (
      axios.post(process.env.REACT_APP_API_URL, {
        "operation": "getStripeSubscriptionStatus"
      }, {
        headers: {
          "Authorization": session.accessToken.jwtToken
        }
      }).then(response => {
        this.setState({ loading: false, subscribed: response.data.subscribed });
      }).catch(error => {
        console.log(error);

        this.setState({ loading: false, error: error.message});
      })
    ));
  };

  includedFeatures = [
    'Unlimited Downloads',
    'Simultaneous Exports',
    'Priority Support',
    'CIK Lookup'
  ]

  render = () => (
    <Section>
      <div className="mx-auto mb-5" id="billing">
        <div className="flex flex-wrap gap-x-5 mb-6">
          <div className="basis-full">
            <div className="mx-auto max-w-2xl sm:text-center">
              <h2 className="text-xl pt-2 mb-4 md:text-3xl tracking-normal md:mt-5">{this.state.subscribed ? "Manage" : "Upgrade"} your Plan</h2>
              <p className="leading-8">
                {this.state.subscribed ? "You are currently on a paid plan with the following features:" : "You are currently utilizing a free account with limited downloads."}
              </p>
            </div>
            {this.state.loading ? (
              <div className="mx-auto pt-2 text-center">
                <p>Please wait one moment...</p>
              </div>
            ) : (
              ( this.state.error ? (
                <p className="text-red-500">{this.state.error}</p>
              ) : (
                <div className="bg-white">
                  <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="overflow-hidden bg-white shadow mx-auto mt-10 max-w-2xl lg:mx-0 lg:flex lg:max-w-none">
                      <div className="p-8 sm:p-10 lg:flex-auto">
                        <h3 className="text-xl md:text-2xl tracking-tight text-gray-900">Export Holdings <span className="italic">Pro</span></h3>
                        <p className="mt-6 text-base leading-7 text-gray-600">
                          {this.state.subscribed ? "" : "Enjoy the features you love without limits and utilize powerful tools that every professional needs."}
                        </p>
                        <div className="mt-10 flex items-center gap-x-4">
                          <h4 className="flex-none text-sm font-semibold leading-6 text-primaryBackground">{this.state.subscribed ? "Included in your account" : "What’s included"}</h4>
                          <div className="h-px flex-auto bg-gray-100" />
                        </div>
                        <ul
                          role="list"
                          className="mt-8 grid grid-cols-1 gap-4 leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
                        >
                          {this.includedFeatures.map((feature) => (
                            <li key={feature} className="flex gap-x-3">
                              <CheckIcon className="h-6 w-5 flex-none text-primaryBackground" aria-hidden="true" />
                              {feature}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                        <div className="mt-1 lg:mr-2 bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                          <div className="mx-auto max-w-xs px-8">
                            {this.state.subscribed ? (
                              <>
                                <Button onClick={this.createStripeCustomerPortalSession}>Manage your Subscription</Button>
                                <p className="mt-6 text-sm leading-6 text-gray-600">
                                  Alter your payment methods, view invoices, or cancel your subscription at any time.
                                </p>
                              </>
                            ) : (
                              <>
                                <p className="my-6 flex items-baseline justify-center gap-x-2">
                                  <span className="text-5xl tracking-tight text-gray-900">$9.99</span>
                                  <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">/ month</span>
                                </p>
                                <Button onClick={this.createStripeCheckoutSession}>Subscribe</Button>
                                <p className="mt-6 text-sm leading-6 text-gray-600">
                                  Invoices and receipts available for easy company reimbursement.
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      <ServiceBar contactLabel="Customer Support" contactDescription="We're here to help answer any questions or concerns about your account or our services." contactPath="/contact?defaultSubject=customer-support" showBackToHome={false} />
    </Section>
  )
}

export default function Billing() {
  const context = React.useContext(AccountContext);

  return <BillingClass context={context} />;
}