import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { ChatBubbleLeftEllipsisIcon, MagnifyingGlassCircleIcon } from '@heroicons/react/24/solid'
import Footer from '../parts/Footer';
import Title from '../parts/Title';

const ServiceBar = ({ contactLabel = 'Contact Us', contactDescription = 'Let us know how we can improve this service.', contactPath = '/contact', showBackToHome = true }) => {
    const links = [
        {
            name: contactLabel,
            href: contactPath,
            description: contactDescription,
            icon: ChatBubbleLeftEllipsisIcon,
        },
        {
            name: 'Find A Fund',
            href: '/',
            description: 'Search for 13F data from our expansive database.',
            icon: MagnifyingGlassCircleIcon,
        }
    ]

    return (
        <div className="mx-auto mt-16 flow-root max-w-lg sm:mt-20">
            <h2 className="sr-only">Popular pages</h2>
            <ul role="list" className="-mt-6 divide-y divide-gray-900/5 border-b border-gray-900/5">
                {links.map((link, linkIdx) => (
                    <li key={linkIdx} className="relative flex gap-x-6 py-6">
                        <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-gray-900/10">
                            <link.icon className="h-6 w-6 text-primaryBackground" aria-hidden="true" />
                        </div>
                        <div className="flex-auto">
                            <h3 className="text-sm font-semibold leading-6 text-gray-900">
                                <a href={link.href}>
                                    <span className="absolute inset-0" aria-hidden="true" />
                                    {link.name}
                                </a>
                            </h3>
                            <p className="mt-2 text-sm leading-6 text-gray-600">{link.description}</p>
                        </div>
                        <div className="flex-none self-center">
                            <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                    </li>
                ))}
            </ul>
            {showBackToHome ? (
            <div className="mt-10 flex justify-center">
                <a href="/" className="text-sm font-semibold leading-6 text-primaryBackground">
                    <span aria-hidden="true">&larr;</span>
                    Back to Home
                </a>
            </div>
            ) : null}
        </div>
    );
}

export default ServiceBar;