const Button = (props) => {
    const buttonClass = "px-3.5 py-2.5 ml-auto w-full text-sm font-semibold max-h-10 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600";

    if (props.color === "blue") {
        return <button {...props} className={buttonClass + " text-white bg-primaryBackground opacity-90 hover:opacity-100"}>{props.children}</button>
    } else {
        return <button {...props} className={buttonClass + " text-primaryBackground bg-gray-100 hover:bg-gray-200"}>{props.children}</button>
    }
};

export default Button;