import React from 'react';

function SimpleList({items}) {
    return (
        <ul role="list" className="divide-y divide-white pb-3">
            {items.map((item, index) => (
                <li key={index} className="py-3">
                    <p className="mt-3 truncate text-sm text-gray-900">
                        <span className="text-gray-900">{item.name}</span> 
                    </p>
                </li>
            ))}
        </ul>
    );
}

export default SimpleList;