import React from "react";
import Title from "../parts/Title";
import Section from "../parts/Section";
import Header from "../parts/Header";
import Footer from "../parts/Footer";
import ServiceBar from "../parts/ServiceBar";
import SimpleList from "../parts/SimpleList";

function NotFound() {

    const debugTips = [
        {
            name: 'Make sure your link in the search bar isn\'t broken.',
        },
        {
            name: 'If you are searching for a fund, it may not be available yet.',
        },
        {
            name: 'You may request additional fund coverage by contacting us.',
        }
    ]

    return (
        <div className="text-center" data-testid="error-component">
            <Header />
            <div className="px-4 py-5 sm:p-6">
                <Section>
                    <Title>Page not Found</Title>
                    <div className="max-w-lg mx-auto bg-gray-100 rounded-sm">
                        <SimpleList items={debugTips} />
                    </div>
                    {/* <ul class="list-inside list-disc ml-6">
                    <li>Make sure your link in the search bar isn't broken.</li>
                    <li>If you are searching for a fund, it may not be available yet.</li>
                    <li>You may request additional fund coverage by contacting us.</li>
                </ul> */}
                    <ServiceBar />
                </Section>
            </div>
            <Footer />
        </div>
    );
}
export default NotFound;