import Button from './Button'
import { Link } from "react-router-dom";

const SearchAgain = ({ name, children }) => {

    return (
        <Link to="/">
           <Button>{children}</Button>
        </Link>
    );
};

export default SearchAgain;