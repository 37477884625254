import Footer from '../parts/Footer';
import Header from '../parts/Header';
import Title from '../parts/Title';
import Section from '../parts/Section';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import QuarterDropdown from '../parts/QuarterDropdown';
import Table from '../parts/Table';
import Share from '../parts/Share';
import Download from '../parts/Download';
import ChangeTables from '../parts/ChangeTables';
import TinyLoader from '../parts/TinyLoader';

import ReusableChart from '../parts/ReusableChart';
import ScrollToTop from '../parts/ScrollToTop';
import SearchAgain from '../parts/SearchAgain';

const genericApexChart = {
    options: {
        chart: {
            id: "generic-chart-init"
        },
        toolbar: {
            show: false
        }
    },
    series: [
        {
            data: [
            ],
        },
    ]
}

const Fund = () => {
    // Messaging:
    const [primaryMessage, setPrimaryMessage] = useState(null);
    const [primarySuccess, setPrimarySuccess] = useState(false);
    const [primaryLoading, setPrimaryLoading] = useState(true);
    const [secondaryMessage, setSecondaryMessage] = useState(null);
    const [secondarySuccess, setSecondarySuccess] = useState(false);
    const [secondaryLoading, setSecondaryLoading] = useState(true);

    // Data:
    const [fundName, setFundName] = useState("");
    const [pathParams, setPathParams] = useState(useParams());
    const [queryParams, setQueryParams] = useSearchParams();
    const [lifetimeData, setLifetimeData] = useState([]);
    const [quarterData, setQuarterData] = useState([]);
    const [portfolioDistributionChartData, setPortfolioDistributionChartData] = useState(genericApexChart);
    const [sectorAnalysisChartData, setSectorAnalysisChartData] = useState(genericApexChart);
    const [lifetimePortfolioValueChartData, setLifetimePortfolioValueChartData] = useState(genericApexChart);
    const [lifetimePortfolioEarningsChartData, setLifetimePortfolioEarningsChartData] = useState(genericApexChart);
    const [lifetimePortfolioEarningsYieldChartData, setLifetimePortfolioEarningsYieldChartData] = useState(genericApexChart);

    // Force view to be at top of page
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // If requested fund IS CHANGED, fetch all quarters metadata for new fund as well as quarter data
    useEffect(() => {
        // Get the CIK from the path parameters:
        const { CIK } = pathParams;
        // Get year and quarter from query string parameters (and convert to integers):
        let requestedYear = queryParams.get("year");
        let requestedQuarter = queryParams.get("quarter");
        let foundYear = null;
        let foundQuarter = null;

        axios.post(process.env.REACT_APP_API_URL, {
            "operation": "getAllQuartersMetadata",
            "payload": {
                "Fund": CIK
            }
        })
        .then(response => {
            // Check to see if the response is empty:
            if (response.data.length === 0) {
                // Redirect to 404 page:
                window.location.href = "/404";
            }

            // Store data from the first request in state

            // ULTRA SUPER HACK: adjusts anomalous portfolio value data (due to x1000 units on original filing)
            let anomaliesFound = false
            let prevPortfolioValue = response.data[response.data.length - 1].portfolioValue
            for (let i = response.data.length - 1; i >= 0; i--) {
                let item = response.data[i]
                if ((item.portfolioValue - prevPortfolioValue) / prevPortfolioValue > 100) {
                    anomaliesFound = true
                }
                if (anomaliesFound === true) {
                    response.data[i].portfolioValue = item.portfolioValue / 1000
                }
                prevPortfolioValue = item.portfolioValue
            }
            // END ULTRA SUPER HACK

            setLifetimeData(response.data);
            for (let quarter of response.data) {
                if (quarter.filingYear === requestedYear && quarter.filingQuarter === requestedQuarter) {
                    foundYear = requestedYear;
                    foundQuarter = requestedQuarter;
                } else {
                    // If there is a specific quarter requested via URL, defer to that filing
                    // Otherwise, use the most recent quarter filing from lifetime metadata
                    if (requestedYear && requestedQuarter) {
                        foundYear = requestedYear;
                        foundQuarter = requestedQuarter;
                    } else {
                        foundYear = response.data[0].filingYear;
                        foundQuarter = response.data[0].filingQuarter;
                    }
                }
            }

            // Use data from the first request to make a second GET request
            axios.post(process.env.REACT_APP_API_URL, {
                "operation": "getQuarterData",
                "payload": {
                    "Fund": response.data[0].CIK,
                    "Year": foundYear,
                    "Quarter": foundQuarter
                }
            })
            .then(response => {
                const quarterDataResponse = response.data;
                quarterDataResponse.filing = JSON.parse(quarterDataResponse.filing)
                // Store data from the second request in state
                if (quarterDataResponse?.filing !== undefined) {
                    setQuarterData(quarterDataResponse);
                    setPrimarySuccess(true);
                }
            })
            .catch(error => {
                setPrimarySuccess(false);
                setPrimaryLoading(false);
                setPrimaryMessage("An error occurred, please try again later.");
                console.error('Error fetching data:', error);
            });
        })
        .catch(error => {
            setPrimarySuccess(false);
            setPrimaryLoading(false);
            setPrimaryMessage("An error occurred, please try again later.");
            console.error('Error fetching data:', error);
        })
    }, [pathParams])

    // If NO CHANGE in fund, get data of newly selected quarter
    useEffect(() => {
        // Get the CIK from the path parameters:
        const { CIK } = pathParams;
        // Get year and quarter from query string parameters (and convert to integers):
        let requestedYear = queryParams.get("year");
        let requestedQuarter = queryParams.get("quarter");

        axios.post(process.env.REACT_APP_API_URL, {
            "operation": "getQuarterData",
            "payload": {
                "Fund": CIK,
                "Year": requestedYear,
                "Quarter": requestedQuarter
            }
        })
        .then(response => {
            const quarterDataResponse = response.data;
            if (quarterDataResponse?.filing) quarterDataResponse.filing = JSON.parse(quarterDataResponse.filing)
            // Store data from the second request in state
            if (quarterDataResponse?.filing !== undefined) {
                setQuarterData(response.data);
            }
        })
        .catch(error => {
            setPrimarySuccess(false);
            setPrimaryLoading(false);
            setPrimaryMessage("An error occurred, please try again later.");
            console.error('Error fetching data:', error);
        });
    }, [queryParams])

    // Populate chart data once all fund data is loaded successfully
    useEffect(() => {
        const portfolioDistributionChartState = {
            options: {
                chart: {
                    id: "portfolio-distribution",
                    toolbar: {
                        show: false
                    }
                },
                theme: {
                    mode: 'dark',
                    monochrome: {
                        enabled: true,
                        color: '#002366',
                        shadeTo: 'dark',
                        shadeIntensity: 0.2
                    },
                },
                tooltip: {
                    enabled: true,
                    enabledOnSeries: undefined,
                    shared: true,
                    followCursor: true,
                    intersect: false,
                    inverseOrder: false,
                    custom: undefined,
                    fillSeriesColor: false,
                    theme: false,
                    y: {
                        formatter: function (val) {
                            return val + '%'
                        }
                    },
                    style: {
                        fontSize: '12px',
                        fontFamily: undefined,
                    },
                    onDatasetHover: {
                        highlightDataSeries: false,
                    },
                    marker: {
                        show: false,
                    },
                    items: {
                        display: "flex",
                    },
                    fixed: {
                        enabled: true,
                        position: 'topRight',
                        offsetX: 0,
                        offsetY: 0,
                    },
                }
            },
            series: [
                {
                    data: [
                    ],
                },
            ],
        }
        if (primarySuccess) {
            for (let position of quarterData.filing.sort((a, b) => b.percentageOfPortfolio - a.percentageOfPortfolio)) {
                portfolioDistributionChartState.series[0].data.push({ x: position.ticker, y: (Math.round(position.percentageOfPortfolio * 100) / 100) })
            }
            setPortfolioDistributionChartData(portfolioDistributionChartState);
        }

        const sectorAnalysisChartState = {
            options: {
                chart: {
                    type: "bar",
                    id: "sector-analysis",
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        borderRadius: 2,
                        borderRadiusApplication: "end",
                        barHeight: '90%',
                        dataLabels: {
                            position: 'bottom'
                        }
                    }

                },
                theme: {
                    mode: 'light',
                    monochrome: {
                        enabled: true,
                        color: '#60a5fa',
                        shadeTo: 'dark',
                        shadeIntensity: 0.2,
                    },
                },
                grid: {
                    show: false
                },
                yaxis: {
                    labels: {
                        show: false
                    }
                },
                xaxis: {
                    labels: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: true,
                    textAnchor: 'start',
                    style: {
                        colors: ['#002366']
                    },
                    formatter: function (val, opt) {
                        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val + "%"
                    },
                    offsetX: 0,
                    dropShadow: {
                        enabled: false
                    }
                },
                tooltip: {
                    enabled: false,
                },
            },
            series: [
                {
                    data: [],
                }
            ],
        }

        if (primarySuccess) {
            const sectorsByPercent = {};
            for (let row of quarterData.filing) {
                let sector = row.sector;
                let percentOfPortfolio = Number(row.percentageOfPortfolio);
                if (sectorsByPercent[sector] !== undefined) {
                    sectorsByPercent[sector] += percentOfPortfolio;
                } else {
                    sectorsByPercent[sector] = percentOfPortfolio;
                }
            }
            const sectorsSortedByPercent = Object.entries(sectorsByPercent).sort((a, b) => b[1] - a[1]);
            sectorsSortedByPercent.map((el) => {
                sectorAnalysisChartState.series[0].data.push({
                    x: el[0],
                    y: el[1].toFixed(2)
                })
            })

            setSectorAnalysisChartData(sectorAnalysisChartState);
        }

        const lifetimePortfolioValueChartState = {
            series: [
                {
                    name: "Value",
                    data: []
                }
            ],
            options: {
                chart: {
                    id: "portfolio-value",
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    logarithmic: true,
                    logBase: Math.exp(1),
                    labels: {
                        show: false
                    },
                    min: 0
                },
                tooltip: {
                    enabled: true,
                    y: {
                        show: true,
                        formatter: (val) => '$' + new Intl.NumberFormat().format(val)
                    }
                }
            }
        }

        if (primarySuccess) {
            let minVal = Infinity
            for (let quarter of lifetimeData) {
                if (quarter.portfolioValue < minVal) minVal = quarter.portfolioValue
                lifetimePortfolioValueChartState.series[0].data.unshift(quarter.portfolioValue)
                lifetimePortfolioValueChartState.options.xaxis.categories.unshift(`${quarter.filingYear} Q${quarter.filingQuarter}`)
            }
            lifetimePortfolioValueChartState.options.yaxis.min = minVal - Math.abs(minVal * 0.5)
            setLifetimePortfolioValueChartData(lifetimePortfolioValueChartState)
        }

        const lifetimePortfolioEarningsChartState = {
            series: [
                {
                    name: "Earnings",
                    data: []
                },
                {
                    name: "Free Cash Flow",
                    data: []
                }
            ],
            options: {
                chart: {
                    id: "portfolio-earnings",
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    labels: {
                        show: false
                    }
                },
                tooltip: {
                    enabled: true,
                    y: {
                        show: true,
                        formatter: (val) => {
                            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(val.toString())
                        }
                    }
                }
            }
        }

        if (primarySuccess) {
            let minVal = Infinity
            for (let quarter of lifetimeData) {
                if (quarter.portfolioEarnings < minVal) minVal = quarter.portfolioEarnings
                lifetimePortfolioEarningsChartState.series[0].data.unshift(quarter.portfolioEarnings)
                lifetimePortfolioEarningsChartState.series[1].data.unshift(quarter.portfolioFCF)
                lifetimePortfolioEarningsChartState.options.xaxis.categories.unshift(`${quarter.filingYear} Q${quarter.filingQuarter}`)
            }
            setLifetimePortfolioEarningsChartData(lifetimePortfolioEarningsChartState)
        }

        const lifetimePortfolioEarningsYieldChartState = {
            series: [
                {
                    name: "Earnings Yield",
                    data: []
                },
                {
                    name: "Free Cash Flow Yield",
                    data: []
                }
            ],
            options: {
                chart: {
                    id: "portfolio-earnings-yield",
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    labels: {
                        show: false
                    }
                },
                tooltip: {
                    enabled: true,
                    y: {
                        show: true,
                        formatter: (val) => parseFloat(val * 100).toFixed(2) + '%'
                    }
                }
            }
        }

        if (primarySuccess) {
            for (let quarter of lifetimeData) {
                lifetimePortfolioEarningsYieldChartState.series[0].data.unshift(quarter.portfolioEarningsYield)
                lifetimePortfolioEarningsYieldChartState.series[1].data.unshift(quarter.portfolioFCFYield)
                lifetimePortfolioEarningsYieldChartState.options.xaxis.categories.unshift(`${quarter.filingYear} Q${quarter.filingQuarter}`)
            }
            setLifetimePortfolioEarningsYieldChartData(lifetimePortfolioEarningsYieldChartState)
        }

        setSecondaryLoading(false)
        setSecondarySuccess(true)
    }, [primarySuccess, quarterData])

    const firstTableHeaders = ["Ticker", "Cusip", "Name of Issuer", "Date", "Change Type", "Value", "Ssh Prnamt", "Percentage of Portfolio", "Previous Percentage", "Change in Shares", "Change Percentage", "Title of Class", "Ssh Prnamt Type", "Sector", "Close", "P/E Ratio", "P/B Ratio", "Price to Revenue", "Price to FCF", "EV/EBIT", "EV/EBITDA", "Operating Margin", "Net Margin", "Asset Turnover", "Leverage Ratio", "Return on Equity", "Current Ratio", "Quick Ratio", "Debt/EBITDA", "Debt/Equity"];
    const firstTableColumnsToUse = ["ticker", "cusip", "nameOfIssuer", "date", "changeType", "value", "sshPrnamt", "percentageOfPortfolio", "previousPercentageOfPortfolio", "changeInShares", "changePercentage", "titleOfClass", "sshPrnamtType", "sector", "close", "peRatio", "pbRatio", "priceToRevenue", "priceToFCF", "EV/EBIT", "EV/EBITDA", "operatingMargin", "netMargin", "assetTurnover", "leverageRatio", "returnOnEquity", "currentRatio", "quickRatio", "debt/EBITDA", "debt/Equity"];

    const links = [
        {
            name: 'Quarterly Changes',
            href:
                '#quarterly-changes',
        },
        {
            name: 'Portfolio Distribution',
            href:
                '#portfolio-distribution',
        },
        {
            name: 'Sector Analysis',
            href:
                '#sector-analysis',
        },
        {
            name: 'Portfolio Value',
            href:
                '#portfolio-value',
        },
        {
            name: 'Earnings & FCF',
            href:
                '#earnings-fcf',
        },
        {
            name: 'Yields',
            href:
                '#yields',
        },
    ]

    return (
        <div data-testid="fund-component">
            <Header />
            {primarySuccess ? (
                <div className="w-full">
                    <Section>
                        <div className="mx-auto mb-5">
                            <div className="flex flex-wrap gap-x-5 mb-6">
                                <div className="basis-full">
                                    <Title>{quarterData?.filing[0]?.filer}</Title>
                                </div>
                                <div className="mt-4 basis-1/3 pl-1">Quarter: &nbsp;&nbsp;
                                    <QuarterDropdown selectedYear={quarterData?.filingYear} selectedQuarter={quarterData?.filingQuarter} CIK={quarterData?.CIK} allFundJsonData={lifetimeData} />
                                </div>
                                <div className="pt-11 sm:mt-3 sm:pt-2 ml-auto basis-3/5 flex gap-x-2 justify-end">
                                    <Share name={fundName + " 13F data for " + quarterData?.filingYear + " Q" + quarterData?.filingQuarter}>Share</Share>
                                    <Download CIK={quarterData?.CIK} year={quarterData?.filingYear} quarter={quarterData?.filingQuarter} name={`${quarterData?.CIK}-${quarterData?.filing[0].filer}-${quarterData?.filingYear}-Q${quarterData?.filingQuarter}.csv`}>
                                        Export
                                    </Download>
                                </div>

                                <div className="mt-8 mx-auto pt-3 w-full">
                                    <div className="grid grid-cols-1 gap-2 sm:grid-cols-3 md:grid-cols-6">
                                        {links.map((link) => (
                                            <div
                                                key={link.href}
                                                className="relative flex items-center space-x-3 border border-gray-300 bg-white px-3 py-2 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-blue-900"
                                            >
                                                {/* <div className="flex-shrink-0">
                                                    <img className="h-10 w-10 rounded-full" src={link.href} alt="" />
                                                </div> */}
                                                <div className="min-w-0 flex-1 text-center">
                                                    <a href={link.href} className="focus:outline-none">
                                                        <span className="absolute inset-0" aria-hidden="true" />
                                                        <p className="text-sm font-medium text-gray-900">{link.name}</p>
                                                    </a>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <Table headers={firstTableHeaders} rows={quarterData?.filing} columnsToUse={firstTableColumnsToUse} />
                        </div>
                        <div id="quarterly-changes">
                            <ChangeTables quarterData={quarterData?.filing} />
                        </div>
                        <div className="flex flex-wrap">

                            <div className="w-full lg:w-1/2 lg:pr-2 pt-10" id="portfolio-distribution">
                                <div className="border-b border-gray-200 pb-5">
                                    <h3 className="text-base font-semibold leading-6 text-gray-900">Portfolio Distribution by %</h3>
                                </div>
                                <div className="w-full">
                                    <ReusableChart className="w-full" type="treemap" state={portfolioDistributionChartData} />
                                    {/* {console.log('FUNDJSONDATA', fundJsonData)} */}
                                </div>
                            </div>

                            <div className="w-full lg:w-1/2 lg:pr-2 pt-10" id="sector-analysis">
                                <div className="border-b border-gray-200 pb-5">
                                    <h3 className="text-base font-semibold leading-6 text-gray-900">Sector Analysis by %</h3>
                                </div>
                                <div className="w-full">
                                    {/* <SectorChart jsonFundData={fundJsonData} /> */}
                                    <ReusableChart type="bar" state={sectorAnalysisChartData} />
                                </div>
                            </div>
                        </div>

                        {secondaryLoading ? (
                            <div>
                                <h2>Loading additional data...</h2>
                                <TinyLoader />
                            </div>
                        ) : (secondarySuccess ? (
                            <div>
                                <div className="border-b border-gray-200 pb-5 pt-8" id="portfolio-value">
                                    <h3 className="text-base font-semibold leading-6 text-gray-900">Historic Portfolio Value</h3>
                                </div>
                                <div className="w-full lg:w-3/4 mx-auto">
                                    <ReusableChart className="w-full" type="line" state={lifetimePortfolioValueChartData} />
                                </div>

                                <div className="border-b border-gray-200 pb-5 pt-8" id="earnings-fcf">
                                    <h3 className="text-base font-semibold leading-6 text-gray-900">Historic Earnings vs FCF</h3>
                                </div>
                                <div className="w-full lg:w-3/4 mx-auto">
                                    <ReusableChart className="w-full" type="line" state={lifetimePortfolioEarningsChartData} />
                                </div>
                                <div className="border-b border-gray-200 pb-5 pt-8" id="yields">
                                    <h3 className="text-base font-semibold leading-6 text-gray-900">Historic Earnings Yields vs FCF Yields</h3>
                                </div>
                                <div className="w-full lg:w-3/4 mx-auto">
                                    <ReusableChart className="w-full" type="line" state={lifetimePortfolioEarningsYieldChartData} />
                                </div>

                            </div>
                        ) : (
                            <Section fullHeight={true}>
                                <Title>An error occurred loading additional data.</Title>
                                <p>{secondaryMessage}</p>
                            </Section>
                        ))}

                        <div className="container mx-auto flex flex-col gap-y-3">
                            <SearchAgain>Find another Fund</SearchAgain>
                            <ScrollToTop>Back to Top</ScrollToTop>
                        </div>

                    </Section>
                </div>
            ) : (primaryLoading ? (
                <Section fullHeight={true}>
                    <Title>Loading...</Title>
                    <TinyLoader />
                </Section>
            ) : (
                <Section fullHeight={true}>
                    <Title>Error</Title>
                    <p>{primaryMessage}</p>
                    <div className="container mx-auto flex flex-col gap-y-3 px-3 md:px-10 py-1 md:py-3">
                        <SearchAgain>Back to Search</SearchAgain>
                    </div>
                </Section>
            ))}
            <Footer />
        </div>
    );
};

export default Fund;