import Button from './Button';

const Share = ({ name, children }) => {
    function share() {
        if (navigator.share) {
            // Allow user to share in apps as well as copy the link:
            navigator.share({
                title: name,
                url: window.location.href
            }).then(() => {})
            .catch((error) => {
                console.log(error);
            });
        } else {
            // Copy link to clipboard:
            navigator.clipboard.writeText(window.location.href).then(() => {})
            .catch((error) => {
                console.log(error);
            });

            // Alert user that link has been copied:
            alert("Link copied to clipboard!");
        }
    };

    return (
        <Button onClick={share}>{children}</Button>
    );
};

export default Share;