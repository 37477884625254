import Button from './Button';

const ScrollToTop = ({ name, children }) => {
    function scrollToTop() {
        window.scrollTo(0, 0);
    };

    return (
        <Button onClick={scrollToTop} color="blue">{children}</Button>
    );
};

export default ScrollToTop;