import React, { useState } from 'react';
import Button from './Button';
import axios from 'axios';
import Popup from './Popup';
import { AccountContext } from '../../cognito/Account';
import { Link } from 'react-router-dom';

import { KeyIcon, FolderArrowDownIcon, UserPlusIcon } from '@heroicons/react/24/outline'


function Download({ name, children, CIK, quarter, year }) {
  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = useState("");

  const features = [
    {
      name: 'Create a FREE account',
      description:
        `Join our community of happy users`,
      icon: KeyIcon,
    },
    {
      name: 'Export holdings data',
      description:
        'Get instant access to over 5,000 SEC filings',
      icon: FolderArrowDownIcon,
    }
  ]

  const { getSession } = React.useContext(AccountContext);

  const handleDownload = () => {
    getSession()
    .then(session => {
      
      axios.post(process.env.REACT_APP_API_URL, {
        "operation": "getPresignedUrl",
        "payload": {
          "Fund": CIK,
          "Year": year,
          "Quarter": quarter
        }
      }, {
        headers: {
          "Authorization": session.accessToken.jwtToken
        }
      })
      .then(res => {
        console.log(res)

        if (res.data.statusCode === 401) { // If the user is not logged in:
          setShowPopup(true);
          return;
        }

        // Create a temporary link element
        const link = document.createElement("a");
        link.href = res.data;
        link.download = name;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        // Use the download attribute to trigger the download directly
        link.click();
      })
      .catch(err => {
        console.log(err)
      });

    })
    .catch(err => { // If the user is not logged in:
      setShowPopup(true);
      return;
    });
  };

  const closePopupCallback = () => {
    setShowPopup(false);
  }

  const icon = (
    <UserPlusIcon className="h-6 w-6 text-primaryBackground" aria-hidden="true" />
  )

  return (
    <>
      {showPopup &&
        <Popup title="Make an account to export data" icon={icon} closePopupCallback={closePopupCallback}>
          <div className="sm:col-span-full">
            <div className="mx-auto border-t text-left mt-6 pt-8 max-w-2xl sm:mt-10 lg:mt-7 lg:pt-8 lg:max-w-4xl">
              <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 px-9">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-16">
                    <dt className="text-sm sm:text-base font-semibold leading-3 sm:leading-7 text-gray-900">
                      <div className="absolute left-0 top-0 flex h-6 w-6 sm:h-10 sm:w-10 items-center justify-center rounded-md bg-primaryBackground">
                        <feature.icon className="h-4 w-4 sm:h-6 sm:w-6 text-white" aria-hidden="true" />
                      </div>
                      {feature.name}
                    </dt>
                    <dd className="mt-1 sm:mt-2 text-xs sm:text-base leading-4 sm:leading-7 text-gray-600">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
            <div className="grid grid-cols-3">
              <div className="mt-9 col-span-full md:col-span-2">
                <div>
                  <label htmlFor="email" className="sr-only">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="block w-full rounded-none border-0 py-2 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    required={true}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email Address"
                  />
                </div>
              </div>
              <div className="mt-3 sm:mt-7 md:mt-9 col-span-full md:col-span-1 md:pl-4">
                <Link to={"/register?prefilledEmail=" + email + "&redirectPath=" + window.location.pathname}><Button color="blue">Get Started</Button></Link>
              </div>
            </div>
            <div className="mt-10 border-t border-gray-900/10 pb-8 pt-8 text-center">
              <>Already have an account? <Link to={"/login?redirectPath=" + window.location.pathname} className="underline">Log in</Link></>
            </div>
          </div>
        </Popup>
      }

      <Button onClick={handleDownload} color="blue">{children}</Button>
    </>
  );
}

export default Download;