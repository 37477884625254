import roundDecimal from './roundDecimal';

export default function formatNumber(value) {
    if (!isNaN(value)) {
        let processedNum = roundDecimal(value).toLocaleString('en', { useGrouping:true });
        if (isNaN(processedNum)) {
            return "--";
        } else {
            return processedNum;
        }
    }
    return value;
};