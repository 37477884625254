import { Outlet } from 'react-router-dom';
import NotFound from './components/pages/NotFound.jsx';
import GeneralError from './components/pages/GeneralError.jsx';
import Home from './components/pages/Home';
import Fund from './components/pages/Fund';
import Contact from './components/pages/Contact';
import Login from './components/pages/Login';
import Register from './components/pages/Register';
import Verify from './components/pages/Verify';
import Profile from './components/pages/Profile';
import ResetPassword from './components/pages/ResetPassword.jsx';
import Settings from './components/pages/Settings.jsx';

// Context:
import { Account } from './cognito/Account.jsx';

const routes = [
    {
        path: "/",
        element: <Outlet />,
        errorElement: <GeneralError />,
        children: [
            {
                path: "/",
                element: <Account><Home /></Account>
            },
            {
                path: "/contact",
                element: <Account><Contact /></Account>
            },
            {
                path: "/fund/:CIK",
                element: <Account><Fund /></Account>
            },
            {
                path: "/login",
                element: <Account><Login /></Account>,
            },
            {
                path: "/register",
                element: <Account><Register /></Account>,
            },
            {
                path: "/verify",
                element: <Account><Verify /></Account>,
            },
            {
                path: "/profile",
                element: <Account><Profile /></Account>,
            },
            {
                path: "/reset-password",
                element: <Account><ResetPassword /></Account>,
            },
            {
                path: "/settings",
                element: <Account><Settings /></Account>,
            },
        ]
    },
    { path: "*", element: <Account><NotFound /></Account> }
]

export default routes;