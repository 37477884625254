import Table from "./Table";

const ChangeTables = ({ quarterData }) => {
    const changeTablesHeaders = ["Ticker", "Change in Shares", "Value"];
    const changeTablesColumnsToUse = ["ticker", "changeInShares", "value"];

    return (
        <div className="pt-10 w-full">
            <div className="border-b border-gray-200 pb-5">
                <h3 className="text-base font-semibold leading-6 text-gray-900">Quarterly Changes</h3>
            </div>
            <div className="mx-auto mt-8">
                {/* Make inline-block move to the top of the div above ^*/}
                <div className="flex flex-wrap">
                    <div className="w-full sm:w-1/2 sm:px-1 lg:w-1/4 lg:px-2">
                        <h2 className="pt-3 pl-3 text-center font-bold">New</h2>
                        <Table headers={changeTablesHeaders} rows={quarterData?.filter((row) => row.changeType === "new")} columnsToUse={changeTablesColumnsToUse} />
                    </div>
                    <div className="w-full sm:w-1/2 sm:px-1 lg:w-1/4 lg:px-2">
                        <h2 className="pt-3 pl-3 text-center font-bold">Addition</h2>
                        <Table headers={changeTablesHeaders} rows={quarterData?.filter((row) => row.changeType === "addition")} columnsToUse={changeTablesColumnsToUse} />
                    </div>
                    <div className="w-full sm:w-1/2 sm:px-1 lg:w-1/4 lg:px-2">
                        <h2 className="pt-3 pl-3 text-center font-bold">Reduction</h2>
                        <Table headers={changeTablesHeaders} rows={quarterData?.filter((row) => row.changeType === "reduction")} columnsToUse={changeTablesColumnsToUse} />
                    </div>
                    <div className="w-full sm:w-1/2 sm:px-1 lg:w-1/4 lg:px-2">
                        <h2 className="pt-3 pl-3 text-center font-bold">Sold All</h2>
                        <Table headers={changeTablesHeaders} rows={quarterData?.filter((row) => row.changeType === "soldall")} columnsToUse={changeTablesColumnsToUse} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangeTables;