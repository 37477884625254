import Footer from '../parts/Footer';
import Header from '../parts/Header';
import Title from '../parts/Title';
import { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import TinyLoader from '../parts/TinyLoader';
import axios from 'axios';

const Home = () => {
    const [search, setSearch] = useState("");
    const [initiateRequest, setInitiateRequest] = useState(false);
    const [timeoutID, setTimeoutID] = useState(null);
    const [message, setMessage] = useState(null);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState(null);

    const [fundToCIKMapping, setFundToCIKMapping] = useState(null)

    const inputRef = useRef(null);

    function updateSearch(e) {
        setSearch(e.target.value.toUpperCase());

        // setInitiateRequest(true) in 500ms and clear any old timeoutID's:
        if (timeoutID) {
            clearTimeout(timeoutID);
        }

        if (e.target.value !== "") {
            const id = setTimeout(() => {
                setInitiateRequest(true);
            }, 500);
            setTimeoutID(id);
        }
    }

    // Automatically focus on the textbox on page load:
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Filter fund-to-CIK mapping (strict matching) every time the user stops typing listing up to 100 results at a time:
    useEffect(() => {
        if (initiateRequest && fundToCIKMapping) {
            setMessage(null);
            setLoading(true);

            for (let i = 0; i < search.length; i++) {
                let searchWord = search.slice(0, i + 1);
                let temp = fundToCIKMapping.filter(val => val[0].slice(0, i + 1) === searchWord).slice(0, 100);
                setResults(temp);
                setMessage(null);
                setSuccess(true);
                setLoading(false);
            }

            setInitiateRequest(false);
            clearTimeout(timeoutID);
            setTimeoutID(null);
        }
    }, [initiateRequest, search, timeoutID]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }

        axios.post(process.env.REACT_APP_API_URL, {
            "operation": "getAllFundsList"
        }).then(response => {
            setFundToCIKMapping(response.data)
        }).catch(err => {
            console.log(err)

            setMessage("An error occurred getting search results, please try again later.");
        })
    }, []);

    return (
        <div data-testid="home-component" className="gridBackground">
            <Header data-testid="header-component"/>
            <div className="w-full h-screen flex justify-center items-center">
                <div className="w-full text-center -mt-44">
                    <Title>Search for 13F Data</Title>
                    <input data-testid="search-input" className="border-primaryBackground border-2 rounded-sm p-4 mt-2 w-5/6 md:w-3/4 focus:outline-none focus:border-opacity-0 focus:ring-2 focus:ring-primaryBackground" name="search" type="text" placeholder="Start typing the name of a fund..." onChange={updateSearch} value={search} ref={inputRef} />
                    <div className="w-full flex justify-center items-center mt-4">
                        {loading ? (
                            <TinyLoader data-testid="tiny-loader"/>
                        ) : (
                            success ? (
                                <ul className="px-1 py-1 rounded-sm bg-gray-200 border-gray-300 border max-h-56 overflow-y-auto w-5/6 md:w-3/4">
                                    {results.length === 0 ? (<div><div>No results were found,</div><div>please try a different search term.</div></div>) : (
                                        <div className="p-2 font-bold">
                                            <div className="inline-block w-1/2 text-left">Fund Name</div>
                                            <div className="inline-block w-1/2 text-left">CIK</div>
                                        </div>
                                    )}
                                    {results.map((item, i) => (
                                        <Link to={"/fund/" + item[1]} key={i}>
                                            <li key={i} style={{ wordSpacing: "2px" }} className="p-2 hover:opacity-80 hover:bg-gray-100 duration-150">
                                                <div className="inline-block w-1/2 text-left">{item[0]}</div>
                                                <div className="inline-block w-1/2 text-left">{item[1]}</div>
                                            </li>
                                        </Link>
                                    ))}
                                </ul>
                            ) : (
                                <div className="text-red-500">{message}</div>
                            )
                        )}
                    </div>
                </div>
            </div>
            <Footer data-testid="footer-component"/>
        </div>
    );
};

export default Home;