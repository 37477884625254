import { Link } from "react-router-dom";

const Footer = () => (
    <footer className="p-4 mt-3 w-full text-center">
      <div className="m-2 text-sm">
      <a href="https://www.george-jeng.com" target="_blank" rel="noreferrer" className="underline">George Jeng</a> x <a href="https://aidandigital.com" target="_blank" rel="noreferrer" className="underline">Aidan Digital</a> <p className="px-2 inline-block">|</p> <Link to="/contact?defaultSubject=report-bug" className="underline">Report a Bug</Link>
      </div>

      <div className="text-xs">
        &copy; {new Date().getFullYear()} Export Holdings Co. All rights reserved.
      </div>
    </footer>
);

export default Footer;