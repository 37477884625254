import Section from "../parts/Section";
import Header from "../parts/Header";
import Footer from "../parts/Footer";
import FormContainer from "../form-parts/FormContainer";
import React from "react";
import UserPool from "../../cognito/UserPool";
import TextInput from "../form-parts/TextInput";
import Notification from "../parts/Notification.jsx";
import { CognitoUser } from "amazon-cognito-identity-js";
import { AccountContext } from "../../cognito/Account.jsx";
import { Navigate } from "react-router-dom";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";

class VerifyClass extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            code: "",
            error: "",
            loading: false,
            success: false,
            showCodeResent: false,
            newEmail: false,
            redirectPath: ""
        };
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, error: null });
    }

    componentDidMount = () => {
        // Set email to the current URL email parameter:
        const params = new URLSearchParams(window.location.search);
        const email = params.get("email");
        const newEmail = params.get("newEmail");

        if (!email) {
            this.setState({ success: true }); // (will redirect to the login page)
        }

        this.setState({ email: params.get("email"), newEmail: newEmail == "true" });

        // Get the redirect URL:
        const redirectPath = params.get("redirectPath");

        if (redirectPath) {
            this.setState({ redirectPath: redirectPath });
        }
    }

    resendCode = () => {
        this.setState({ loading: true, showCodeResent: false });
        
        // Construct a user object to resend the code to:
        const user = new CognitoUser({
            Username: this.state.email,
            Pool: UserPool
        });

        // Resend the code:
        user.resendConfirmationCode((err, data) => {
            if (err) {
                this.setState({ loading: false, error: err.message });
            } else {
                this.setState({ loading: false, showCodeResent: true });
            }
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({ loading: true, showCodeResent: false });

        // Construct a user object to confirm:
        const user = new CognitoUser({
            Username: this.state.email,
            Pool: UserPool
        });

        if (this.state.newEmail) {
            // Verify the user's new email address:
            this.props.context.verifyAttribute("email", this.state.code)
            .then(() => {
                this.setState({ loading: false, success: true });
            })
            .catch(err => {
                this.setState({ loading: false, error: err.message });
            });
        } else {
            // Confirm the new user:
            user.confirmRegistration(this.state.code, true, (err, data) => {
                if (err) {
                    this.setState({ loading: false, error: err.message });
                } else {
                    this.setState({ loading: false, success: true });
                }
            });
        }
    }

    render = () => (
        <div>
            <Header />
            <Section fullHeight={true}>
                <FormContainer
                    title="Verify your Email Address"
                    submitLabel="Verify"
                    onSubmit={this.handleSubmit}
                    loading={this.state.loading}
                    success={this.state.success}
                    error={this.state.error}
                    onSuccess={() =>
                        this.state.newEmail ? (
                            <Navigate to="/profile?updatedProfileSuccess=true" replace /> 
                        ) : (
                            <Navigate to={"/login?registrationSuccess=true&redirectPath=" + this.state.redirectPath} replace />
                        )
                    }
                >
                    <TextInput
                        label="Code"
                        name="code"
                        type="text"
                        value={this.state.code}
                        onChange={this.handleChange}
                        note={() => 
                            <>
                                Enter the code sent to your email address
                                {/* Can only resend a confirmation code for a new user, not an updated email. */ this.state.newEmail ? "." : <> or <button className="cursor-pointer underline" onClick={this.resendCode}>resend the code</button>.</>}
                            </>
                        }
                    >
                    </TextInput>
                </FormContainer>
            </Section>
            <Footer />
            <Notification show={this.state.showCodeResent} toggleShowCallback={() => this.setState({ showCodeResent: false })}>
                Code was resent to: <br />{this.state.email}
            </Notification>
        </div>
    );
}

export default function Verify() {
    const context = React.useContext(AccountContext);

    return <VerifyClass context={context} />;
}