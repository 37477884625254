import Title from "../parts/Title";
import Header from "../parts/Header";
import Footer from "../parts/Footer";
import FormContainer from "../form-parts/FormContainer";
import TextInput from "../form-parts/TextInput";
import Button from "../parts/Button.jsx";
import React from "react";
import { AccountContext } from "../../cognito/Account.jsx";
import { Navigate, Link } from "react-router-dom";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { toHaveAccessibleDescription } from "@testing-library/jest-dom/matchers.js";

class SettingsClass extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            currentEmail: "", // (for the reset password link, must keep separate from the mutable email attribute)
            error: "",
            loading: true,
            success: false,
            emailNotVerified: false
        };
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, error: null });
    }

    componentDidMount = () => {
        this.props.context.getUserAttributes()
        .then(attributes => {
            this.setState({
                loading: false,
                name: attributes.find(attribute => attribute.Name === "name").Value,
                email: attributes.find(attribute => attribute.Name === "email").Value,
                currentEmail: attributes.find(attribute => attribute.Name === "email").Value // (for the reset password link, must keep separate from the mutable email attribute)
            });
        })
        .catch(err => {});
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true });

        // Create a list of attributes to update:
        const attributes = [
            new CognitoUserAttribute({ Name: "name", Value: this.state.name }),
            new CognitoUserAttribute({ Name: "email", Value: this.state.email })
        ];

        this.props.context.updateAttributes(attributes)
        .then(data => {
            if (this.state.email !== this.state.currentEmail) {
                // Make sure user verifies their new email (it won't be updated before verification is complete):
                this.setState({ emailNotVerified: true })
            } else {
                this.setState({ loading: false, success: true });
            }
        })
        .catch(err => {
            this.setState({ loading: false, error: err.message });
        });
    }

    render = () => (
        <div>
            <Header />
            <Title title="Settings" />
            <FormContainer
                title="Settings"
                submitLabel="Save"
                onSubmit={this.handleSubmit}
                loading={this.state.loading}
                success={this.state.success}
                error={this.state.error}
                onSuccess={() => <Navigate to="/profile?updatedProfileSuccess=true" />}
                otherOption={() => (
                    <div className="col-span-full">
                        <label className="block font-medium leading-6 text-gray-900 text-left">
                            Password
                        </label>
                        <div className="mt-2">
                            <Link to={"/reset-password?email=" + this.state.currentEmail}>
                                <Button>Set a new Password</Button>
                            </Link>
                        </div>
                    </div>
                )}
            >
                <TextInput
                    label="Name"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                />
                <TextInput
                    label="Email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                />
            </FormContainer>

            { // Redirect user to verify their new email if they attempted to update it:
                this.state.emailNotVerified ? <Navigate to={"/verify?newEmail=true&email=" + this.state.email} replace /> : null
            }
            
            <Footer />
        </div>
    )
}

export default function Settings() {
    const context = React.useContext(AccountContext);

    return <SettingsClass context={context} />;
}