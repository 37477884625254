import { Link } from "react-router-dom";

const Menu = ({items, fontSize, pipes}) => {
    const liClass = "hover:cursor-pointer hover:opacity-80 duration-200 inline-block text-" + (fontSize ? fontSize : "lg");

    return (
        <div className="md:px-4">
            {items.map((item, i) => (
                <li key={i} className="inline-block">
                    <span className={liClass + " px-1.5"}>
                        <Link to={item.to}>
                            <div target={item.target}>
                                <p className="inline-block mx-3 -mb-1.5"><item.icon /></p>
                                <p className="hidden sm:inline-block">{item.name}</p>
                            </div>
                        </Link>
                    </span>
                    {pipes && i < items.length - 1 ? <span className={liClass + " text-gray-300 md:text-black"}>|</span> : null}
                </li>
            ))}
        </div>
    )
};

export default Menu;