import React, { Component } from "react";
import Chart from "react-apexcharts";

class ReusableChart extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={this.props.state.options}
              series={this.props.state.series}
              type={this.props.type}
              width="100%"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ReusableChart;