import TinyLoader from "../parts/TinyLoader";
import Title from "../parts/Title";
import Button from "../parts/Button";

const FormContainer = ({ title, submitLabel, onSubmit, loading, success, error, children, onSuccess = () => null, otherOption = () => null, showFormOnSuccess = false }) => (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-3xl">
            <div className="overflow-hidden bg-white shadow mt-5">
                <div className="px-4 py-5 sm:p-6">
                    <div className="text-center">
                        <Title>{title}</Title>
                    </div>
                    <form onSubmit={onSubmit} method="post">
                        <div className="space-y-12">
                            <div className="border-t border-gray-900/10 pb-8">
                                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    {!loading ? (!success || showFormOnSuccess ?
                                        <>
                                            {children}
                                            <div className="col-span-full">
                                                <Button type="submit" color="blue">{submitLabel}</Button> 
                                                {error ? <div className="text-sm leading-6 text-red-500 mt-2">{error}</div> : null}
                                            </div>
                                        </> :
                                        <div className="col-span-full">
                                            {onSuccess()}
                                        </div>
                                    ) :
                                    <div className="col-span-full">
                                        <div className="w-full place-items-center grid">
                                            <TinyLoader />
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                    {otherOption() !== null ?
                    <div className="border-t border-gray-900/10 pb-8 pt-8 text-center">
                        {otherOption()}
                    </div>
                    : null}
                </div>
            </div>
        </div>
    </div>
);

export default FormContainer;