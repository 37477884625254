import { CognitoUserPool } from "amazon-cognito-identity-js";

const USERPOOL_ID = process.env.REACT_APP_COGNITO_USERPOOL_ID;
const CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_APP_ID;

const poolData = {
    UserPoolId: USERPOOL_ID,
    ClientId: CLIENT_ID,
};

export default new CognitoUserPool(poolData);