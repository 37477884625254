import React, { createContext } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from "./UserPool";

const AccountContext = createContext();

function Account(props) {
    // Authenticate a user using their username (email) and password:
    async function authenticate(username, password) {
        return await new Promise((resolve, reject) => {
            const user = new CognitoUser({ Username: username, Pool: UserPool });
            const authDetails = new AuthenticationDetails({ Username: username, Password: password });

            user.authenticateUser(authDetails, {
                onSuccess: data => {
                    resolve(data);
                },
                onFailure: err => {
                    console.error("onFailure:", err);
                    reject(err);
                }
            });
        });
    }

    // Get the current user's session. This function is used in other account-related functions in order to get up to date tokens.
    // WARNING: Do not use this function to get user information, as the payload associated with the ID token only refreshes every 60 minutes. Use getUserAttributes() instead.
    async function getSession() {
        return await new Promise((resolve, reject) => {
            const user = UserPool.getCurrentUser();

            if (user) {
                user.getSession(function(err, session) {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(session);
                    }
                });

            } else {
                reject(new Error("No current user."));
            }
        });
    }

    // Get the current user's attributes:
    async function getUserAttributes() {
        return await new Promise((resolve, reject) => {
            const user = UserPool.getCurrentUser();

            if (user) {
                user.getSession(function(err, session) {
                    if (err) {
                        reject(err);
                    } else {
                        user.getUserAttributes(function(err, attributes) {
                            if (err) {
                                reject(err);
                            } else {
                                resolve(attributes);
                            }
                        });
                    }
                });

            } else {
                reject(new Error("No current user."));
            }
        });
    }

    function logout() {
        const user = UserPool.getCurrentUser();
        if (user) {
            user.signOut();
        }
    }

    async function updateAttributes(attributes) {
        return await new Promise((resolve, reject) => {
            const user = UserPool.getCurrentUser();

            if (user) {
                user.getSession(function(err, session) {
                    if (err) {
                        reject(err);
                    } else {
                        user.updateAttributes(attributes, function(err, result) {
                            if (err) {
                                reject(err);
                            } else {
                                resolve(result);
                            }
                        });
                    }
                });

            } else {
                reject(new Error("No current user."));
            }
        });
    }

    async function verifyAttribute(attributeName, confirmationCode) {
        return await new Promise((resolve, reject) => {
            const user = UserPool.getCurrentUser();

            if (user) {
                user.getSession(function(err, session) {
                    if (err) {
                        reject(err);
                    } else {
                        user.verifyAttribute(attributeName, confirmationCode, {
                            onSuccess: function(result) {
                                resolve(result);
                            },
                            onFailure: function(err) {
                                reject(err);
                            }
                        });
                    }
                });

            } else {
                reject(new Error("No current user."));
            }
        });
    }

    return (
        <AccountContext.Provider value={{ authenticate, getSession, getUserAttributes, logout, updateAttributes, verifyAttribute }}>
            {props.children}
        </AccountContext.Provider>
    );
}

export { Account, AccountContext };