import { useEffect, useState } from 'react';
import formatNumber from "../../utils/formatNumber";
import { ChevronDownIcon, ChevronUpIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import sortRows from '../../utils/sortRows';
import Tooltip from '../parts/Tooltip';

const changeTypeColorMap = {
    reduction: "bg-amber-200",
    addition: "bg-green-200",
    new: "bg-blue-200",
    soldall: "bg-red-200",
    nochange: "bg-white"
}

const Table = ({ headers, rows, columnsToUse }) => {

    const [currentSort, setCurrentSort] = useState(['ticker', false]);
    const [sortedRows, setSortedRows] = useState(rows);

    useEffect(() => {
        if (rows.length > 0) {
            const sortRowsResult = sortRows(rows, currentSort[0], currentSort[1]);
            setSortedRows(sortRowsResult.slice());
        }
    }, [currentSort, rows])

    return (
        <div className="overflow-x-auto">
            <table className="text-sm my-2 unselectable w-full">
                <thead>
                    <tr className="border-b border-gray-300">
                        {headers.map((header, i) => (
                            <th key={i}
                                onClick={() => setCurrentSort([columnsToUse[i], !currentSort[1]])}
                                columnindex={columnsToUse[i]} className={
                                    (i === 0 ? "sticky left-0 z-10" : "") +
                                    (currentSort[0] === columnsToUse[i] ? " text-black border-b-4 border-violet-500" : "text-gray-600 border-none") +
                                    " hover:cursor-pointer bg-white"}>

                                {header}

                                <ChevronUpDownIcon className={(currentSort[0] === columnsToUse[i] ? "hidden" : "") + " -mr-1 h-5 w-5 text-gray-400 text-center inline-block"}
                                    aria-hidden="true" />
                                <ChevronUpIcon className={(currentSort[0] === columnsToUse[i] && currentSort[1] === false ? "" : "hidden") + " -mr-1 h-5 w-5 text-black text-center inline-block"}
                                    aria-hidden="true" />
                                <ChevronDownIcon className={(currentSort[0] === columnsToUse[i] && currentSort[1] === true ? "" : "hidden") + " -mr-1 h-5 w-5 text-black text-center inline-block"}
                                    aria-hidden="true" />
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {sortedRows.map((row, i) => (
                        <tr className={(changeTypeColorMap[row.changeType] ? changeTypeColorMap[row.changeType] : "") + " border-b"} key={i}>
                            {columnsToUse.map((columnName, j) => (
                                <td className={(j === 0 ? "font-bold sticky left-0 z-20 " + changeTypeColorMap[row.changeType] : "z-0") + " px-4 py-1"} key={`${i}${j}`}>
                                    <Tooltip content={headers[j]}>
                                        {formatNumber(row[columnName])}
                                    </Tooltip>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
};

export default Table;